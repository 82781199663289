/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

import "./app.css"
import Cookies from "universal-cookie"

const Layout = ({ children }) => {
  let isVerify = new Cookies()
  if (isVerify.get("accesstoken") === undefined) {
    return null
  } else return <>{children}</>
}
export default Layout
