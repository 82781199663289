exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-be-heard-br-index-js": () => import("./../../../src/pages/be_heard/br/index.js" /* webpackChunkName: "component---src-pages-be-heard-br-index-js" */),
  "component---src-pages-be-heard-fr-index-js": () => import("./../../../src/pages/be_heard/fr/index.js" /* webpackChunkName: "component---src-pages-be-heard-fr-index-js" */),
  "component---src-pages-be-heard-index-js": () => import("./../../../src/pages/be_heard/index.js" /* webpackChunkName: "component---src-pages-be-heard-index-js" */),
  "component---src-pages-be-heard-mx-index-js": () => import("./../../../src/pages/be_heard/mx/index.js" /* webpackChunkName: "component---src-pages-be-heard-mx-index-js" */),
  "component---src-pages-be-heard-pt-index-js": () => import("./../../../src/pages/be_heard/pt/index.js" /* webpackChunkName: "component---src-pages-be-heard-pt-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-dough-index-js": () => import("./../../../src/pages/cookie_dough/index.js" /* webpackChunkName: "component---src-pages-cookie-dough-index-js" */),
  "component---src-pages-extragum-index-js": () => import("./../../../src/pages/extragum/index.js" /* webpackChunkName: "component---src-pages-extragum-index-js" */),
  "component---src-pages-galaxy-limited-edition-index-js": () => import("./../../../src/pages/galaxy-limited-edition/index.js" /* webpackChunkName: "component---src-pages-galaxy-limited-edition-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maltesers-bake-against-the-machine-index-js": () => import("./../../../src/pages/MaltesersBakeAgainstTheMachine/index.js" /* webpackChunkName: "component---src-pages-maltesers-bake-against-the-machine-index-js" */),
  "component---src-pages-maltesers-bake-against-the-machine-terms-conditions-js": () => import("./../../../src/pages/MaltesersBakeAgainstTheMachine/terms-conditions.js" /* webpackChunkName: "component---src-pages-maltesers-bake-against-the-machine-terms-conditions-js" */),
  "component---src-pages-mms-amazon-faq-js": () => import("./../../../src/pages/MMSAmazon/faq.js" /* webpackChunkName: "component---src-pages-mms-amazon-faq-js" */),
  "component---src-pages-mms-amazon-index-js": () => import("./../../../src/pages/MMSAmazon/index.js" /* webpackChunkName: "component---src-pages-mms-amazon-index-js" */),
  "component---src-pages-mms-amazon-prizes-js": () => import("./../../../src/pages/MMSAmazon/prizes.js" /* webpackChunkName: "component---src-pages-mms-amazon-prizes-js" */),
  "component---src-pages-mms-amazon-terms-js": () => import("./../../../src/pages/MMSAmazon/terms.js" /* webpackChunkName: "component---src-pages-mms-amazon-terms-js" */),
  "component---src-pages-orbit-ugly-sweaters-agegate-js": () => import("./../../../src/pages/orbit_ugly_sweaters/agegate.js" /* webpackChunkName: "component---src-pages-orbit-ugly-sweaters-agegate-js" */),
  "component---src-pages-orbit-ugly-sweaters-index-js": () => import("./../../../src/pages/orbit_ugly_sweaters/index.js" /* webpackChunkName: "component---src-pages-orbit-ugly-sweaters-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-project-gallery-js": () => import("./../../../src/pages/project-gallery.js" /* webpackChunkName: "component---src-pages-project-gallery-js" */),
  "component---src-pages-skittles-gummies-agegate-js": () => import("./../../../src/pages/SkittlesGummies/agegate.js" /* webpackChunkName: "component---src-pages-skittles-gummies-agegate-js" */),
  "component---src-pages-skittles-gummies-index-js": () => import("./../../../src/pages/SkittlesGummies/index.js" /* webpackChunkName: "component---src-pages-skittles-gummies-index-js" */),
  "component---src-pages-skittles-pride-index-js": () => import("./../../../src/pages/SkittlesPride/index.js" /* webpackChunkName: "component---src-pages-skittles-pride-index-js" */),
  "component---src-pages-snickers-hunger-insurance-agegate-js": () => import("./../../../src/pages/snickers-hunger-insurance/agegate.js" /* webpackChunkName: "component---src-pages-snickers-hunger-insurance-agegate-js" */),
  "component---src-pages-snickers-hunger-insurance-index-js": () => import("./../../../src/pages/snickers-hunger-insurance/index.js" /* webpackChunkName: "component---src-pages-snickers-hunger-insurance-index-js" */),
  "component---src-pages-snickers-nfl-agegate-js": () => import("./../../../src/pages/snickers-nfl/agegate.js" /* webpackChunkName: "component---src-pages-snickers-nfl-agegate-js" */),
  "component---src-pages-snickers-nfl-enter-js": () => import("./../../../src/pages/snickers-nfl/enter.js" /* webpackChunkName: "component---src-pages-snickers-nfl-enter-js" */),
  "component---src-pages-snickers-nfl-index-js": () => import("./../../../src/pages/snickers-nfl/index.js" /* webpackChunkName: "component---src-pages-snickers-nfl-index-js" */),
  "component---src-pages-snickers-nfl-official-rules-js": () => import("./../../../src/pages/snickers-nfl/official-rules.js" /* webpackChunkName: "component---src-pages-snickers-nfl-official-rules-js" */),
  "component---src-pages-snickers-nfl-prizes-js": () => import("./../../../src/pages/snickers-nfl/prizes.js" /* webpackChunkName: "component---src-pages-snickers-nfl-prizes-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-treat-by-mars-index-js": () => import("./../../../src/pages/TreatByMars/index.js" /* webpackChunkName: "component---src-pages-treat-by-mars-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

